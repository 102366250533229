
import { computed, defineComponent, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { useHead } from "@vueuse/head";

import CModal from "@/components/Modal.vue";
import CFormInputText from "@/components/form/InputText.vue";
import CFormInputNumber from "@/components/form/InputNumber.vue";
import PCalendar from "primevue/calendar";
import PInputSwitch from "primevue/inputswitch";
import PMultiSelect from "primevue/multiselect";

import { dayOfWeek, addHoursMask, formatDateEn } from "@/libs/utils";
import { AgendaAttendanceForm } from "@/store/agenda/types";

const VAttendanceForm = defineComponent({
  name: "VAttendanceForm",
  components: { CModal, CFormInputText, CFormInputNumber, PCalendar, PInputSwitch, PMultiSelect },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const _id = Number(route.params._id);
    const isEditForm = route.name?.toString().includes("-edit");
    const attendanceID = Number(route.query.id);
    const date = String(route.query.date);

    useHead({ title: `${isEditForm ? "Editar" : "Cadastrar"} atendimento | obmed` });

    const attendanceDays = computed(() => store.state.agenda.attendanceDays);
    const attendanceDay = computed(() => attendanceDays.value.find((item) => item.id === attendanceID));
    const selectedDaysOfWeek = ref<string[]>([
      dayOfWeek[new Date(date.includes("T") ? date : date.replaceAll("-", "/")).getDay()]?.value,
    ]);

    const form = reactive<AgendaAttendanceForm>({
      cd_agenda: _id,
      dt_vigencia_final: "",
      dt_vigencia_inicial: "",
      hr_fim_atendimento: "",
      hr_inicio_atendimento: "",
      ie_ativo: true,
      qtd_duracao_atendimento: 0,
      qtd_retorno: 0,
      qtd_vaga: 1,
      ie_atende_feriado: false,
    });
    const loading = reactive({ submit: false, deletion: false });
    const confirmDeletion = ref(false);

    function handleClose() {
      router.back();
    }

    async function handleDeletion() {
      if (confirmDeletion.value === false) {
        confirmDeletion.value = true;
        setTimeout(() => {
          confirmDeletion.value = false;
        }, 3000);

        return;
      }

      confirmDeletion.value = false;
      loading.deletion = true;
      const response = await store.dispatch("deleteAgendaAttendanceDay", { _id, attendanceID });
      loading.deletion = false;

      if (response?.status === 200) {
        store.dispatch("getAgendaAttendanceDays", { _id });
        handleClose();
      }
    }

    async function onSubmit() {
      function showInvalidField(fieldName: string) {
        store.commit("addToast", { summary: `O campo "${fieldName}" é obrigatório!`, severity: "warn" });
      }

      if (!selectedDaysOfWeek.value.length) return showInvalidField("Dia da semana");

      loading.submit = true;
      const response = isEditForm
        ? await store.dispatch("editAgendaAttendanceDay", {
            _id: attendanceID,
            form: {
              ...form,
              nm_dias_semana: selectedDaysOfWeek.value.join(","),
              nm_dia_semana: selectedDaysOfWeek.value.join(","),
              dt_vigencia_inicial: formatDateEn(String(form.dt_vigencia_inicial)),
              dt_vigencia_final: formatDateEn(String(form.dt_vigencia_final)),
            },
          })
        : await store.dispatch("addAgendaAttendanceDay", {
            _id,
            form: {
              ...form,
              nm_dias_semana: selectedDaysOfWeek.value.join(","),
              dt_vigencia_inicial: formatDateEn(String(form.dt_vigencia_inicial)),
              dt_vigencia_final: formatDateEn(String(form.dt_vigencia_final)),
            },
          });
      loading.submit = false;

      if (response?.status === 200) {
        store.dispatch("getAgendaAttendanceDays", { _id });
        handleClose();
      }
    }

    function applyForm() {
      form.dt_vigencia_final = attendanceDay.value?.dt_vigencia_final
        ? new Date(attendanceDay.value.dt_vigencia_final.replaceAll("-", "/"))
        : "";
      form.dt_vigencia_inicial = attendanceDay.value?.dt_vigencia_inicial
        ? new Date(attendanceDay.value.dt_vigencia_inicial.replaceAll("-", "/"))
        : date !== "undefined"
        ? new Date(date.includes("T") ? date : date.replaceAll("-", "/"))
        : new Date();
      form.hr_fim_atendimento = attendanceDay.value?.hr_fim_atendimento || "";
      form.hr_inicio_atendimento = attendanceDay.value?.hr_inicio_atendimento || "";
      form.ie_ativo = attendanceDay.value?.ie_ativo ?? true;
      form.qtd_duracao_atendimento = attendanceDay.value?.qtd_duracao_atendimento || 0;
      form.qtd_retorno = attendanceDay.value?.qtd_retorno || 0;
      form.qtd_vaga = attendanceDay.value?.qtd_vaga || 1;
      form.ie_atende_feriado = attendanceDay.value?.ie_atende_feriado ?? false;

      selectedDaysOfWeek.value =
        (attendanceDay.value?.nm_dias_semana || attendanceDay.value?.nm_dia_semana)?.split(",") || [];
    }

    applyForm();
    watch(attendanceDay, applyForm);

    return {
      ...{ form, loading, dayOfWeek, isEditForm, confirmDeletion, selectedDaysOfWeek },
      ...{ handleClose, handleDeletion, onSubmit },
      ...{ addHoursMask, formatDateEn },
    };
  },
});

export default VAttendanceForm;
